import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DialogComponentService } from '@merchant-portal/app/components/dialog/dialog.component.service';
import { UtilService } from '@merchant-portal/app/services/util.service';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, from } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import {
  ILoginApiResponse,
  ILoginSubmit,
} from '../../../models/login/login.interface';
import { AuthService } from '../../auth.service';
import { FingerprintService } from '../../fingerprint/fingerprint.service';
import {
  LoginParams,
  LoginState,
  LoginStoreFactory,
} from '../factory/login-store.factory';

const initialState: LoginState = {
  errorMessage: '',
  isOtpRequired: false,
  isCountDownStarted: false,
  isDeviceRecognized: false,
  isLoading: false,
};

@Injectable()
export class LoginStoreService
  extends ComponentStore<LoginState>
  implements LoginStoreFactory
{
  constructor(
    private authService: AuthService,
    private router: Router,
    private dialogComponentService: DialogComponentService,
    private utilService: UtilService,
    private fingerprintService: FingerprintService,
  ) {
    super(initialState);
  }

  errorMessage$ = this.select((state) => state.errorMessage);
  isOtpRequired$ = this.select((state) => state.isOtpRequired);
  isCountDownStarted$ = this.select((state) => state.isCountDownStarted);
  isDeviceRecognized$ = this.select((state) => state.isDeviceRecognized);
  isLoading$ = this.select((state) => state.isLoading);
  vm$ = this.select({
    errorMessage: this.errorMessage$,
    isOtpRequired: this.isOtpRequired$,
    isCountDownStarted: this.isCountDownStarted$,
    isDeviceRecognized: this.isDeviceRecognized$,
    isLoading: this.isLoading$,
  });

  readonly login = this.effect<LoginParams>((loginParams$) =>
    loginParams$.pipe(
      tap(() => this.patchState({ isLoading: true })),
      switchMap(({ email, password, isRemember }) =>
        from(this.getFingerprint(email)).pipe(
          switchMap((fingerprint) =>
            this.processLogin(email, password, isRemember, fingerprint).pipe(
              tap((res) => {
                this.patchState({ isLoading: false });
                this.handleLoginResponse(res, email, isRemember, fingerprint);
              }),
              catchError((error: HttpErrorResponse) => {
                this.patchState({ isLoading: false });
                return this.handleLoginError(error);
              }),
            ),
          ),
        ),
      ),
    ),
  );

  // Helper function: Get fingerprint
  private getFingerprint(email: string): Promise<string> {
    return this.fingerprintService.getFingerprint({
      email,
      event: 'login',
    });
  }

  // Helper function: Process login
  private processLogin(
    email: string,
    password: string,
    isRemember: boolean,
    fingerprint: string,
  ) {
    const loginForm: ILoginSubmit = { email, password, isRemember };
    return this.authService.login(loginForm, fingerprint);
  }

  // Helper function: Handle login response
  private handleLoginResponse(
    res: ILoginApiResponse,
    email: string,
    isRemember: boolean,
    fingerprint: string,
  ): void {
    this.authService.setFingerPrintId(email, fingerprint);

    if (isRemember) {
      this.authService.setIsRemember();
    }

    if (res?.data?.accessToken) {
      this.handleAccessToken(res.data, email);
    } else if (res?.data?.token) {
      this.handleOtpToken(res.data.token, email);
    } else {
      this.handleFallbackToken(res.data.token ?? res.data.accessToken, email);
    }
  }

  // Helper function: Handle access token
  private handleAccessToken(data: any, email: string): void {
    this.patchState({ isDeviceRecognized: true });
    this.authService.setAccessToken(data.accessToken || '');
    this.checkAccessToken();

    if (data.userInfo) {
      this.authService.setUserInfo(data.userInfo);
      this.authService.setRefreshToken(data.refreshToken || '');
    }
  }

  // Helper function: Handle OTP token
  private handleOtpToken(token: string, email: string): void {
    this.patchState({
      isOtpRequired: true,
      errorMessage: '',
      isCountDownStarted: true,
    });
    this.authService.setTempToken(token);
    this.authService.setEmail(email);
  }

  // Helper function: Handle fallback token
  private handleFallbackToken(token: string, email: string): void {
    this.patchState({
      isOtpRequired: true,
      errorMessage: '',
      isCountDownStarted: true,
    });
    this.authService.setTempToken(token);
    this.authService.setEmail(email);
  }

  // Helper function: Handle login errors
  private handleLoginError({ error }: HttpErrorResponse) {
    if (error.errors.includes('suspended')) {
      const suspendedTime = this.utilService.parseSuspendedTime(error.errors);
      if (suspendedTime) {
        const remainingMinutes =
          this.utilService.getRemainingMinutes(suspendedTime);
        this.showSuspendedDialog(remainingMinutes);
      }
    } else if (error.errors.includes('please wait')) {
      this.patchState({
        isOtpRequired: false,
        errorMessage: error.errors,
        isCountDownStarted: false,
      });
    } else {
      this.patchState({ errorMessage: error.errors });
    }
    return EMPTY;
  }

  showSuspendedDialog(suspendedTime: number) {
    this.dialogComponentService.openConfirmationDialog({
      width: '530px',
      height: '275px',
      closable: true,
      body: {
        title: 'Code Request Suspended',
        message: `Your code verification request is currently suspended.<br>Please try again after ${suspendedTime} minutes.`,
        closeText: 'Close',
        confirmText: 'Contact Helpdesk',
        isDoNothing: true,
      },
    });
  }

  disableOtpRequire = () => {
    this.patchState({ isOtpRequired: false });
  };

  checkAccessToken() {
    if (this.authService.getAccessToken()) {
      this.router.navigateByUrl('/dashboard');
    }
  }

  getFingerPrintId(email: string) {
    return this.authService.getFingerPrintId(email) || '';
  }

  readonly checkStoredFingerprint = this.effect<string>((trigger$) =>
    trigger$.pipe(
      switchMap((email) =>
        from(this.fingerprintService.getFingerprint()).pipe(
          tap((currentFingerprint) => {
            const storedFingerprint = this.getFingerPrintId(email);
            if (storedFingerprint && storedFingerprint === currentFingerprint) {
              this.patchState({ isDeviceRecognized: true });
            } else {
              this.patchState({ isDeviceRecognized: false });
            }
          }),
        ),
      ),
    ),
  );
}
