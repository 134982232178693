import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BlockLoadingComponent } from '@merchant-portal/app/components/loading/block-loading/block-loading.component';
import { NgxCurrencyDirective } from 'ngx-currency';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BreadcrumbComponent } from 'projects/merchant-portal/src/app/components/breadcrumb/breadcrumb.component';
import { SingleTransactionComponent } from './single-transaction.component';

@NgModule({
  declarations: [SingleTransactionComponent],
  imports: [
    RouterModule,
    CommonModule,
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    DropdownModule,
    InputTextareaModule,
    BreadcrumbComponent,
    NgxCurrencyDirective,
    BlockLoadingComponent,
  ],
  exports: [SingleTransactionComponent],
})
export class SingleTransactionModule {}
