import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Paginator, PaginatorModule, PaginatorState } from 'primeng/paginator';

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [PaginatorModule],
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnChanges {
  @ViewChild('paginator', {
    static: true,
  })
  paginator: Paginator | undefined;

  @Input() totalRecords = 0;
  @Input() rows = 0;
  @Output() pageChange = new EventEmitter<number>();
  @Input() page = 1;
  @Input() indexPage = 1;

  private skipNextEmit = false;

  onPageChange(event: PaginatorState) {
    if (!this.skipNextEmit) {
      this.pageChange.emit(event.page);
    }
    this.skipNextEmit = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['page']) {
      setTimeout(() => {
        this.paginator?.changePage(changes['page'].currentValue);
      });
    }
    if (changes['indexPage']) {
      this.skipNextEmit = true;
      setTimeout(() => {
        this.paginator?.changePage(changes['indexPage'].currentValue - 1);
      });
    }
  }
}
