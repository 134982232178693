import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { DialogComponentService } from '@merchant-portal/app/components/dialog/dialog.component.service';
import { UserAccessService } from '@merchant-portal/app/core/user-access/services/user-access.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { withdrawalDestinationType } from '../pages/payment/constant/withdrawal-destination.constant';
import { PaymentApiService } from '../pages/payment/services/payment.api.service';
import { MenuAndPermissionApiService } from '../services/menu-and-permission.service';
import { MerchantFeeService } from '../services/merchant-fee.service';
import { MerchantProductService } from '../services/merchant-product.service';
import { DashboardAction } from './dashboard.action';

@Injectable({
  providedIn: 'root',
})
export class DashboardEffects {
  private merchantFeeApi = inject(MerchantFeeService);
  private menuAndPermissionsApi = inject(MenuAndPermissionApiService);
  private userAccessService = inject(UserAccessService);
  private merchantProductService = inject(MerchantProductService);
  private dialogService = inject(DialogComponentService);
  private paymentService = inject(PaymentApiService);
  private store = inject(Store);

  private actions$ = inject(Actions);

  getDisbursement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardAction.getDisbursementFee),
      mergeMap(() =>
        this.merchantFeeApi.getDisbursementsConfigFee().pipe(
          map((response) =>
            DashboardAction.getDisbursementSuccess({
              maxAmount: response.data?.maxAmount ?? 250000000,
              minAmount: response.data?.minAmount ?? 10000,
              fee:
                response.data?.feeDetail.totalAmount ??
                response.data?.feeDetail.amount ??
                0,
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              DashboardAction.getDisbursementFailed({
                error: error?.error?.errors,
              }),
            ),
          ),
        ),
      ),
    );
  });

  getUserMenus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardAction.requestUserMenus),
      mergeMap((action) =>
        this.userAccessService.getUserMenu(action.id).pipe(
          map((response) =>
            DashboardAction.requestUserMenusSuccess({
              menus: response,
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(DashboardAction.requestUserMenusFailed({ error: error.error })),
          ),
        ),
      ),
    );
  });

  getMenuAndPermissions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardAction.getMenuAndPermissions),
      mergeMap(() =>
        this.menuAndPermissionsApi.getMenuAndPermissions().pipe(
          map((response) =>
            DashboardAction.getMenuAndPermissionsSuccess({
              menus: response?.data ?? [],
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              DashboardAction.getMenuAndPermissionsFailed({
                error: error?.error?.errors,
              }),
            ),
          ),
        ),
      ),
    );
  });

  getMerchantProductPermissions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardAction.getMerchantProductPermission),
      mergeMap(() =>
        this.merchantProductService.getActiveProducts().pipe(
          map((response) =>
            DashboardAction.getMerchantProductPermissionSuccess({
              products: response?.data ?? [],
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              DashboardAction.getMerchantProductPermissionFailed({
                error: error?.error?.errors,
              }),
            ),
          ),
        ),
      ),
    );
  });

  // Withdrawal effects
  requestWithdrawal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardAction.requestWithdrawal),
      tap(() => {
        this.store.dispatch(
          DashboardAction.setWithdrawalLoading({ loading: true }),
        );
      }),
      mergeMap(({ balance, isWallet }) =>
        this.paymentService
          .getWithdrawalPrepare(isWallet ? 'WALLET' : undefined)
          .pipe(
            mergeMap((data) => {
              const defaultPayload = {
                availableBalance: Number(balance?.value ?? 0),
                bankAccounts: [],
                accountName: '',
                merchantId: '',
              };

              if (
                (data.code !== '00' || !data.data) &&
                data.error?.message
                  ?.toLowerCase()
                  .includes('bank account not found')
              ) {
                return this.openWithdrawalDialog(defaultPayload);
              }

              if (data.code !== '00' || !data.data) {
                return of(
                  DashboardAction.withdrawalPrepareFailed({
                    error: data.error?.message || 'Unknown error occurred',
                  }),
                );
              }

              return this.openWithdrawalDialog(data.data);
            }),
            catchError((error) =>
              of(
                DashboardAction.withdrawalPrepareFailed({
                  error: error?.error?.message || 'Unknown error occurred',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  private openWithdrawalDialog(account: any) {
    return this.dialogService.openWithdrawalRequestDialog(account).onClose.pipe(
      mergeMap((res) => {
        if (!res)
          return of(
            DashboardAction.withdrawalPrepareFailed({ error: 'Dialog closed' }),
          );

        const { amount, destination } = res;

        if (!destination || destination.code === '') {
          return of(
            DashboardAction.withdrawalPrepareFailed({
              error: 'Please select a withdrawal destination.',
            }),
          );
        }

        if (
          destination?.code === withdrawalDestinationType.BANK_TRANSFER &&
          account.bankAccounts?.length === 0
        ) {
          return of(
            DashboardAction.withdrawalPrepareFailed({
              error: 'Please contact customer service to add a bank account.',
            }),
          );
        }

        const bankAccount = account.bankAccounts[0] || {};
        const requestBody = {
          amount,
          destination,
          accountName: account.accountName,
          beneficiaryAccountName: bankAccount.beneficiaryAccountName || '',
          beneficiaryAccountNo: bankAccount.beneficiaryAccountNo || '',
          beneficiaryBankCode: bankAccount.beneficiaryBankCode || '',
          beneficiaryBankName: bankAccount.beneficiaryBankName || '',
        };

        return this.openConfirmationDialog(requestBody);
      }),
    );
  }

  private openConfirmationDialog(data: any) {
    const config = {
      width: '550px',
      height: 'fit-content',
      title: 'Withdraw Confirmation',
      message:
        'Make sure all information is correct before proceeding with the withdrawal request.',
      body: data,
    };

    return this.dialogService
      .openWithdrawalConfirmationDialog(config)
      .onClose.pipe(
        mergeMap((confirmed) => {
          if (!confirmed)
            return of(
              DashboardAction.withdrawalPrepareFailed({
                error: 'Confirmation cancelled',
              }),
            );

          let request = {};
          if (
            data.destination?.code === withdrawalDestinationType.BANK_TRANSFER
          ) {
            request = {
              amount: data.amount,
              beneficiaryAccountNo: data.beneficiaryAccountNo,
              beneficiaryBankCode: data.beneficiaryBankCode,
              accountName: data.accountName,
              destination: data.destination?.code,
            };
          } else {
            request = {
              amount: data.amount,
              accountName: data.accountName,
              destination: data.destination?.code,
              destinationAccountName: 'DISBURSEMENT',
            };
          }
          return of(DashboardAction.confirmWithdrawal({ request }));
        }),
      );
  }

  confirmWithdrawal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardAction.confirmWithdrawal),
      mergeMap(({ request }) =>
        this.dialogService
          .openPinValidationDialog({
            width: '400px',
            height: 'auto',
          })
          .onClose.pipe(
            switchMap((pin) => {
              if (!pin)
                return of(
                  DashboardAction.withdrawalFailed({
                    error: 'PIN validation cancelled',
                  }),
                );
              return this.paymentService.requestWithdrawal(pin, request).pipe(
                map(() =>
                  DashboardAction.withdrawalSuccess({
                    destination: request.destination,
                  }),
                ),
                catchError((error: HttpErrorResponse) => {
                  const message = error?.error?.message
                    ?.toLowerCase()
                    .includes('insufficient')
                    ? 'Your request exceeds your available balance and cannot be processed.'
                    : error?.error?.message || 'Unknown error occurred';
                  return of(
                    DashboardAction.withdrawalFailed({
                      error: message,
                    }),
                  );
                }),
              );
            }),
          ),
      ),
    ),
  );

  withdrawalSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardAction.withdrawalSuccess),
        tap(({ destination }) => {
          const isBankTransfer =
            destination === withdrawalDestinationType.BANK_TRANSFER;
          this.dialogService
            .openSuccessDialog({
              width: '400px',
              height: 'auto',
              body: {
                title: 'Withdrawal Request Successful',
                message: isBankTransfer
                  ? 'Your withdrawal request has been submitted. Please check your bank account.'
                  : 'Your withdrawal request has been submitted. Please check your Payout Balance.',
                closeText: 'Close',
                closeValue: true,
              },
            })
            .onClose.subscribe(() => {
              location.reload();
            });
        }),
      ),
    { dispatch: false },
  );

  withdrawalFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardAction.withdrawalFailed),
        tap(({ error }) => {
          this.dialogService
            .openSuccessDialog({
              width: '400px',
              height: 'auto',
              body: {
                title: 'Withdrawal Request Failed',
                message: error,
                closeText: 'Close',
                closeValue: true,
              },
            })
            .onClose.subscribe(() => {
              location.reload();
            });
        }),
      ),
    { dispatch: false },
  );
}
