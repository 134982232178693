import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IWithdrawalPrepareResponse,
  TransferSettledBalanceRequest,
  WithdrawalRequest,
} from '@merchant-portal/app/models/payment/payment.interface';
import {
  AvailableBalance,
  BalanceResponse,
} from '@merchant-portal/app/models/transaction-history/transaction-history.interface';
import { environment } from '@merchant-portal/environments/environment';
import { catchError, Observable, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {
  public url = environment.backend_portal_url + 'api/v1/';

  constructor(private http: HttpClient) {}

  getWithdrawalPrepare(accountName?: string): Observable<IWithdrawalPrepareResponse> {
    return this.http
      .get<IWithdrawalPrepareResponse>(
        `${this.url}withdrawals/preparation${accountName ? `?accountName=${accountName}` : ''}`
      )
      .pipe(
        catchError((val) => {
          return of(val);
        }),
      );
  }

  requestWithdrawal(
    pin: string,
    withdrawalRequest: WithdrawalRequest,
  ): Observable<any> {
    const pinEncodedBase64 = window.btoa(pin);
    const idempotencyKey = uuidv4();
    const headers = new HttpHeaders({
      'X-Request-PIN': pinEncodedBase64,
      'X-Idempotency-Key': idempotencyKey,
      'Content-Type': 'application/json',
    });

    return this.http.post(this.url + 'withdrawals', withdrawalRequest, {
      headers,
    });
  }

  requestTransferSettledBalance(
    pin: string,
    transferBalanceRequest: TransferSettledBalanceRequest,
  ): Observable<any> {
    const pinEncodedBase64 = window.btoa(pin);
    const idempotencyKey = uuidv4();
    const headers = new HttpHeaders({
      'X-Request-PIN': pinEncodedBase64,
      'X-Idempotency-Key': idempotencyKey,
      'Content-Type': 'application/json',
    });

    return this.http.post(
      this.url + 'withdrawals/balance',
      transferBalanceRequest,
      {
        headers,
      },
    );
  }

  getTotalBalance(
    balanceParams: AvailableBalance,
  ): Observable<BalanceResponse> {
    const params = new HttpParams().set('usecase', balanceParams.usecase);
    return this.http.get<BalanceResponse>(`${this.url}balances`, { params });
  }
}
