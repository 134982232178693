<div class="transaction-dialog" *ngIf="vm$ | async as vm">
  <div
    *ngIf="! (isDisbursement(vm.currentTransactionHistory?.type) || isWithdrawal(vm.currentTransactionHistory?.type))"
    class="content-wrap">
    <div class="bottom-container">
      <div class="bottom-div">
        <div class="bottom-item">
          <span>Created Date</span>
          <span class="black-item">{{
            vm.currentTransactionHistory?.createdAt | date : "medium"
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Type</span>
          <span class="black-item">
            {{
            vm.currentTransactionHistory
            ? typeLabelMap[
            vm.currentTransactionHistory | appTransactionType
            ]
            : ""
            }}
          </span>
        </div>
        <div class="bottom-item">
          <span>Transaction ID</span>
          <span class="black-item" style="width: 223px; text-align: right">{{
            vm.currentTransactionHistory?.id
            }}</span>
        </div>
        <div *ngIf="(vm.currentTransactionHistory | appTranscationDetail).feeDetail as feeDetail" class="bottom-item">
          <span>Linked Transaction ID</span>
          <span class="black-item" style="width: 223px; text-align: right">{{
            (feeDetail)?.linkedId
            }}</span>
        </div>
        <div *ngIf="(vm.currentTransactionHistory | appTranscationDetail).paymentDetails as paymentDetail"
          class="bottom-item">
          <span>Payment Method</span>
          <span class="black-item" style="width: 223px; text-align: right">{{ (paymentDetail)?.paymentMethodType ?? null
            | paymentMethodTransform : '' }}</span>
        </div>
        <div class="bottom-item">
          <span>Amount</span>
          <span class="black-item" style="font-weight: 700">{{
            vm.currentTransactionHistory?.amount
            | currency : "IDR" : "symbol" : "1.0-0" : "id"
            }}</span>
        </div>
      </div>
    </div>
    <div class="wrap-button">
      <button (click)="cancel()" sty class="pg-button">Close</button>
    </div>
  </div>

  <div *ngIf="(vm.currentTransactionHistory | appTranscationDetail).disbursementDetails as disbursement"
    class="content-wrap">
    <div class="bottom-container">
      <div class="bottom-div">
        <div class="bottom-item">
          <span>Created Date</span>
          <span class="black-item">{{
            vm.currentTransactionHistory?.createdAt | date : "medium"
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Last Update</span>
          <span class="black-item">{{
            vm.currentTransactionHistory?.updatedAt | date : "medium"
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Type</span>
          <span class="black-item">
            {{
            vm.currentTransactionHistory
            ? typeLabelMap[
            vm.currentTransactionHistory | appTransactionType
            ]
            : ""
            }}
          </span>
        </div>
        <div class="bottom-item">
          <span>Created By</span>
          <span class="black-item">{{
            disbursement.createdBy
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Sender</span>
          <span class="black-item">{{
            disbursement.senderName
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Reference ID</span>
          <span class="black-item" style="width: 223px; text-align: right">{{
            disbursement.referenceId
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Remarks</span>
          <span class="black-item">{{
            vm.currentTransactionHistory?.remarks
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Failed Reason</span>
          <span class="black-item" style="width: 223px; text-align: right">{{
            vm.currentTransactionHistory?.failedReason
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Amount</span>
          <span class="black-item" style="font-weight: 700">{{
            vm.currentTransactionHistory?.amount
            | currency : "IDR" : "symbol" : "1.0-0" : "id"
            }}</span>
        </div>
      </div>
      <hr />
      <div class="bottom-div">
        <div class="bottom-item">
          <span class="black-item" style="font-weight: 700">Disbursement Detail</span>
        </div>
        <div class="bottom-item">
          <span>Transaction Status</span>
          <span [ngClass]="'status' + vm.currentTransactionHistory?.status">{{
            vm.currentTransactionHistory?.status
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Transaction ID</span>
          <span class="black-item" style="width: 223px; text-align: right">{{
            vm.currentTransactionHistory?.id
            }}</span>
        </div>

        <div class="bottom-item">
          <span>Bank Reference</span>
          <span class="black-item">{{
            disbursement.bankReference ?? "-"
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Bank Name</span>
          <span class="black-item">{{
            disbursement.bankName
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Account Number</span>
          <span class="black-item">{{
            disbursement.accountNumber
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Beneficiary</span>
          <span class="black-item">{{
            disbursement.beneficiary
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Fee</span>
          <span class="black-item">{{
            disbursement.fee
            | currency : "IDR" : "symbol" : "1.0-0" : "id"
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Total</span>
          <span class="black-item" style="font-weight: 700">
            {{
            disbursement?.currency == "IDR"
            ? (disbursement.total | currency : "IDR" : "symbol" : "1.0-0" : "id")
            : disbursement?.currency + ' ' + (disbursement.total | currency : disbursement?.currency : '' : '1.2-2' :
            'en')
            }}
          </span>
        </div>
      </div>
      <hr />
      <div class="bottom-div" style="padding-bottom: 23px">
        <div class="bottom-item">
          <span class="black-item" style="font-weight: 700">Approval Detail</span>
        </div>
        <div class="bottom-item">
          <span>Approval Status</span>
          <div>
            <span [ngClass]="
                'status' + disbursement.approvalStatus
              ">{{ disbursement.approvalStatus }}</span>
          </div>
        </div>
        <div class="bottom-item">
          <span>Approval Date</span>
          <span class="black-item">{{
            disbursement.approvalDate
            | date : "medium"
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Approved By</span>
          <span class="black-item">{{
            disbursement.approvalBy
            }}</span>
        </div>
      </div>
    </div>
    <div class="wrap-button">
      <button (click)="cancel()" sty class="pg-button">Close</button>
    </div>
  </div>

  <div *ngIf="(vm.currentTransactionHistory | appTranscationDetail).withdrawalDetails as withdrawal"
    class="content-wrap">
    <div class="bottom-container">
      <div class="bottom-div">
        <div class="bottom-item">
          <span>Created Date</span>
          <span class="black-item">{{
            vm.currentTransactionHistory?.createdAt | date : "medium"
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Last Update</span>
          <span class="black-item">{{
            vm.currentTransactionHistory?.updatedAt | date : "medium"
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Type</span>
          <span class="black-item">
            {{
            vm.currentTransactionHistory
            ? typeLabelMap[
            vm.currentTransactionHistory | appTransactionType
            ]
            : ""
            }}
          </span>
        </div>
        <div class="bottom-item">
          <span>Created By</span>
          <span class="black-item">{{
            withdrawal.createdBy
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Amount</span>
          <span class="black-item" style="font-weight: 700">{{
            vm.currentTransactionHistory?.amount
            | currency : "IDR" : "symbol" : "1.0-0" : "id"
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Status</span>
          <span [ngClass]="'status' + vm.currentTransactionHistory?.status">{{
            vm.currentTransactionHistory?.status
            }}</span>
        </div>
        <div *ngIf="vm.currentTransactionHistory?.status == 'FAILED'" class="bottom-item">
          <span>Failed Reason</span>
          <span class="black-item" style="width: 223px; text-align: right">{{
            vm.currentTransactionHistory?.failedReason
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Transaction ID</span>
          <span class="black-item" style="width: 223px; text-align: right">{{
            vm.currentTransactionHistory?.id
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Bank Reference</span>
          <span class="black-item">{{
            withdrawal.bankReference ?? "-"
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Bank Name</span>
          <span class="black-item">{{
            withdrawal.bankName
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Account Number</span>
          <span class="black-item">{{
            withdrawal.accountNumber
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Beneficiary</span>
          <span class="black-item">{{
            withdrawal.beneficiary
            }}</span>
        </div>
      </div>

    </div>
    <div class="wrap-button">
      <button (click)="cancel()" sty class="pg-button">Close</button>
    </div>
    <app-block-loading [show]="vm.isLoading"></app-block-loading>
  </div>