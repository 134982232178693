import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgLoaderModule } from '@merchant-portal/app/components/icon/svg-loader/svg-loader/svg-loader.module';
import { BlockLoadingComponent } from '@merchant-portal/app/components/loading/block-loading/block-loading.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { NavbarBannerComponent } from "../../components/navbar-banner/navbar-banner.component";
import { NavbarModule } from '../../components/navbar/navbar.module';
import { SidebarMenuModule } from '../../components/sidebar/sidebar.module';
import { CoreModule } from '../../core/core.module';
import { DashboardComponent } from './dashboard.component';
import { DisbursementModule } from './pages/disbursement/disbursement.module';
import { MenuAndPermissionApiService } from './services/menu-and-permission.service';
import { MerchantFeeService } from './services/merchant-fee.service';
import { MerchantProductService } from './services/merchant-product.service';
import { DashboardEffects } from './store/dashboard.effect';
import { dashboardFeature } from './store/dashboard.reducer';

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    ButtonModule,
    RouterModule,
    SidebarMenuModule,
    NavbarModule,
    DisbursementModule,
    CoreModule,
    ToastModule,
    StoreModule.forFeature(dashboardFeature.name, dashboardFeature.reducer),
    EffectsModule.forFeature([DashboardEffects]),
    BlockLoadingComponent,
    SvgLoaderModule,
    NavbarBannerComponent
],
  providers: [MessageService, MerchantFeeService, MenuAndPermissionApiService, MerchantProductService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardModule {}
