import { createFeature, createReducer, on } from '@ngrx/store';
import { IMenuResponse } from '@merchant-portal/app/models/menu/menu.interface';
import { ISidebar } from '@merchant-portal/app/models/sidebar/sidebar.interface';
import { MenuAndPermissionApiService } from '../services/menu-and-permission.service';
import { DashboardAction } from './dashboard.action';

export interface DashboardState {
  loading: boolean;
  routeLoading: boolean;
  disbursementFee: number;
  disbursementConfigFee: {
    minAmount: number;
    maxAmount: number;
  };
  prevUrl: string;
  waitNotification: boolean;
  menu: IMenuResponse | null;
  menuList: ISidebar[] | null;
  permissionList: string[] | null;
  merchantProductPermission: string[] | null;
  accessForbiddenPage: boolean;
  // Withdrawal state
  withdrawal: {
    loading: boolean;
    error: string | null;
  };
}

const initialState: DashboardState = {
  loading: false,
  routeLoading: false,
  disbursementFee: 2500,
  disbursementConfigFee: {
    minAmount: 10000,
    maxAmount: 250000000,
  },
  prevUrl: '',
  waitNotification: false,
  menu: null,
  menuList: null,
  permissionList: null,
  merchantProductPermission: null,
  accessForbiddenPage: false,
  // Initial withdrawal state
  withdrawal: {
    loading: false,
    error: null
  }
};

export const dashboardFeature = createFeature({
  name: 'dashboard',
  reducer: createReducer(
    initialState,
    // Dashboard actions
    on(DashboardAction.getDisbursementFee, (state) => ({
      ...state,
      loading: true,
    })),
    on(DashboardAction.getDisbursementSuccess, (state, { fee, maxAmount, minAmount }) => ({
      ...state,
      loading: false,
      disbursementFee: fee,
      disbursementConfigFee: {
        minAmount,
        maxAmount,
      },
    })),
    on(DashboardAction.getDisbursementFailed, (state) => ({
      ...state,
      loading: false,
      disbursementFee: initialState.disbursementFee,
    })),
    on(DashboardAction.setPreviousUrl, (state, { url }) => ({
      ...state,
      prevUrl: url,
    })),
    on(DashboardAction.setNotification, (state, { status }) => ({
      ...state,
      waitNotification: status,
    })),
    on(DashboardAction.requestUserMenus, (state) => ({
      ...state,
      menu: null,
    })),
    on(DashboardAction.requestUserMenusSuccess, (state, { menus }) => ({
      ...state,
      menu: menus,
    })),
    on(DashboardAction.requestUserMenusFailed, (state) => ({
      ...state,
      menu: null,
    })),
    on(DashboardAction.getMenuAndPermissions, (state) => ({
      ...state,
      loading: true,
    })),
    on(DashboardAction.getMenuAndPermissionsSuccess, (state, { menus }) => {
      const menuList = menus
        .filter((menu) => menu.type === 'sidebar')
        .map(MenuAndPermissionApiService.mapMenuItemToSidebar);
      const permissionList = menus.flatMap((menuItem) =>
        menuItem.permissions.map((permission) => permission.slug),
      );
      const childPermission = menus
        .flatMap((menuItem) =>
          menuItem.children.flatMap((child) => child.permissions),
        )
        .flatMap((permission) => permission.slug);
      return {
        ...state,
        loading: false,
        menuList,
        permissionList: [...permissionList, ...childPermission],
      };
    }),
    on(DashboardAction.getMenuAndPermissionsFailed, (state) => ({
      ...state,
      loading: false,
      menuList: null,
      permissionList: null,
    })),
    on(DashboardAction.requestRoute, (state) => ({
      ...state,
      routeLoading: true,
      accessForbiddenPage: false,
    })),
    on(DashboardAction.requestRouteSuccess, (state) => ({
      ...state,
      routeLoading: false,
    })),
    on(DashboardAction.requestRouteFailed, (state) => ({
      ...state,
      routeLoading: false,
      accessForbiddenPage: true,
    })),
    on(DashboardAction.getMerchantProductPermission, (state) => ({
      ...state,
      loading: true,
    })),
    on(DashboardAction.getMerchantProductPermissionSuccess, (state, { products }) => {
      const merchantProducts = products.map(product => product.productName);
      return {
        ...state,
        loading: false,
        merchantProductPermission: merchantProducts,
      };
    }),
    on(DashboardAction.getMerchantProductPermissionFailed, (state) => ({
      ...state,
      loading: false,
      merchantProductPermission: [],
    })),

    // Withdrawal actions
    on(DashboardAction.setWithdrawalLoading, (state, { loading }) => ({
      ...state,
      withdrawal: {
        ...state.withdrawal,
        loading
      }
    })),
    on(DashboardAction.withdrawalPrepareFailed, (state, { error }) => ({
      ...state,
      withdrawal: {
        loading: false,
        error
      }
    })),
    on(DashboardAction.withdrawalFailed, (state, { error }) => ({
      ...state,
      withdrawal: {
        loading: false,
        error
      }
    })),
    on(DashboardAction.withdrawalSuccess, (state) => ({
      ...state,
      withdrawal: {
        loading: false,
        error: null
      }
    }))
  ),
});
