<div class="transaction-dialog">
    <div class="content-wrap">
        <div class="title-wrap">
            <h2>{{ title }}</h2>
            <span [innerHTML]="subTitle"></span>
        </div>
        <div class="transaction-detail wrapper">
            <span class="status">{{ status }}</span>

            <span>Count</span>
            <span class="transaction-detail__value">
                {{ total }}
            </span>
            <span style="font-weight: 700;">Total Amount</span>
            <span class="transaction-detail__value hightlight">{{
                amount | defaultValue : 0
                | currency : "IDR" : "symbol" : "1.0-0" : "id"
                }}</span>

        </div>
        <div class="wrap-button">
            <button (click)="close()" class="pg-button">Close</button>
        </div>
    </div>
</div>