<body>
  <ng-container *ngIf="vm$ | async as vm">
    <img class="img-logo" [src]="'../../../assets/images/iki.png'" alt="" />
    <div class="session">
      <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <h4>Welcome back!</h4>
        <span>Please enter your registered email and password</span>
        <div class="input-form">
          <label for="email">Email:</label>
          <span class="p-input-icon-left">
            <i class="pi pi-envelope"></i>
            <input type="text" formControlName="email" pInputText />
          </span>
        </div>
        <div class="warning-message">
          <div *ngIf="
              loginForm.get('email')?.hasError('required') &&
              loginForm.get('email')?.touched
            ">
            <i class="pi pi-exclamation-triangle"></i> Email is required.
          </div>
          <div *ngIf="
              loginForm.get('email')?.hasError('email') &&
              loginForm.get('email')?.touched
            ">
            <i class="pi pi-exclamation-triangle"></i> Invalid email format.
          </div>
        </div>
        <div class="input-form">
          <label for="password">Password:</label>
          <span class="p-input-icon-left">
            <i class="pi pi-lock"></i>
            <input type="password" name="password" id="password" autocomplete="off" formControlName="password"
              pInputText />
          </span>
        </div>
        <div class="warning-message">
          <div *ngIf="
              loginForm.get('password')?.hasError('required') &&
              loginForm.get('password')?.touched
            ">
            <i class="pi pi-exclamation-triangle"></i> Password is required.
          </div>
          <div *ngIf="vm.errorMessage">
            <i class="pi pi-exclamation-triangle"></i> {{ vm.errorMessage }}
          </div>
        </div>
        <div class="input-form">
          <p-checkbox *ngIf="!vm.isDeviceRecognized" formControlName="isRemember" [binary]="true"
            [label]="'Remember this device for 30 days'" name="group" />
        </div>
        <button pButton class="pg-button" [disabled]="loginForm.invalid" type="submit">
          Log in
        </button>
        <a [routerLink]="['/forgot-password']" routerLinkActive="router-link-active">Forgot Password</a>
      </form>
    </div>
    <app-block-loading [show]="vm.isLoading"></app-block-loading>
    <app-otp [hidden]="!vm.isOtpRequired" [displayOtpDialog]="vm.isOtpRequired" (dialogChange)="disableOtpRequire()"
      [countDownStarted]="vm.isCountDownStarted" mode="dialog"></app-otp>

  </ng-container>

</body>