import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentStatusTransform',
  standalone: true,
})
export class PaymentStatusTransformPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'REQUIRE_ACTION':
        return 'Waiting for user action';
      case 'REQUIRE_CONFIRMATION':
        return 'Waiting for confirmation';
      case 'REQUIRE_PAYMENT_METHOD':
        return 'Require Payment Method';
      case 'PROCESSING':
        return 'Processing';
      case 'CANCELLED':
        return 'Cancelled';
      case 'PENDING':
        return 'Pending';
      case 'SUCCESS':
        return 'Success';
      case 'FAILED':
        return 'Failed';
      case 'VOID':
        return 'Void';
      case 'PAID':
        return 'Paid';
      case 'EXPIRED':
        return 'Expired';
      default:
        return value;
    }
  }
}
