import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/id';
import { Component } from '@angular/core';
import { DefaultValuePipe } from '@merchant-portal/app/pipe/default-value.pipe';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

registerLocaleData(localeFr, 'id');
@Component({
  selector: 'app-beneficiary-limit-exceeded-dialog',
  standalone: true,
  imports: [CommonModule, DefaultValuePipe, ],
  templateUrl: './beneficiary-limit-exceeded-dialog.component.html',
  styleUrls: ['./beneficiary-limit-exceeded-dialog.component.scss']
})


export class BeneficiaryLimitExceededDialogComponent {
  title: string = 'Payout Declined';
  subTitle: string = '';
  amount = 0;
  total = 0;
  status = 'FAILED';

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ){
    this.title = this.dialogConfig.data?.title || this.title;
    this.subTitle = this.dialogConfig.data?.subTitle;
    this.status = this.dialogConfig.data?.status || this.status;
    this.amount = this.dialogConfig.data?.amount;
    this.total = this.dialogConfig.data?.total;
  }

  close() {
    this.ref.close(false);
  }
}
