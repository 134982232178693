export enum MESSAGE_TYPE {
  MESSAGE = 'message',
}

export enum MESSAGE_VARIANT {
  SUCCESS = 'success',
  INFO = 'info',
  WARN = 'warn',
  DANGER = 'error',
}
