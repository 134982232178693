import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, effect, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@merchant-portal/app/core/auth.service';
import {
  MESSAGE_TYPE,
  MESSAGE_VARIANT,
} from '@merchant-portal/app/core/message/message.constant';
import { ToastMessageFactory } from '@merchant-portal/app/core/message/toast-message-factory.service';
import { dashboardFeature } from '@merchant-portal/app/features/dashboard/store/dashboard.reducer';
import { NavbarBannerStateService } from '@merchant-portal/app/services/navbar-banner-state.service';
import { Store } from '@ngrx/store';
import { DialogComponentService } from 'projects/merchant-portal/src/app/components/dialog/dialog.component.service';
import { DisbursementCutOffStatusResponse } from 'projects/merchant-portal/src/app/models/disbursement/disbursement.interface';
import {
  Bank,
  BeneficiaryResponse,
  SingleTransactionData,
  SingleTransactionResponse,
} from 'projects/merchant-portal/src/app/models/disbursement/single-transaction/single-transaction.interface';
import {
  Observable,
  Subject,
  catchError,
  filter,
  finalize,
  of,
  switchMap,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { SingleTransactionService } from './services/single-transaction.service';

@Component({
  selector: 'app-single-transaction',
  templateUrl: './single-transaction.component.html',
  styleUrls: ['./single-transaction.component.scss'],
})
export class SingleTransactionComponent implements OnInit, OnDestroy {
  singleTransactionForm: FormGroup;
  isCurrentPasswordValid = false;
  errorMessage = '';
  bankList: Bank[] = [];
  private destroy$ = new Subject<void>();
  userData = inject(AuthService).getUserInfo();
  store = inject(Store);
  fee = this.store.selectSignal(dashboardFeature.selectDisbursementFee);
  configFee = this.store.selectSignal(
    dashboardFeature.selectDisbursementConfigFee,
  );
  lastAccountNumber: string = '';
  lastBankCode: string = '';
  private toastService = inject(ToastMessageFactory);
  isLoading = false;
  isInProgress = false;
  isStillPending = false;
  maxAmount = 0;
  overBookingValidation = {
    enable: false,
    amount: 0,
  };

  constructor(
    private fb: FormBuilder,
    private dialogComponentService: DialogComponentService,
    private singleTransactionService: SingleTransactionService,
    private router: Router,
    private bannerStateService: NavbarBannerStateService,
  ) {
    this.singleTransactionForm = this.fb.group({
      beneficiaryBankCode: ['', [Validators.required]],
      beneficiaryBankName: ['', [Validators.required]],
      beneficiaryAccountNo: ['', [Validators.required]],
      beneficiaryAccountName: ['', [Validators.required]],
      amount: [0],
      remark: ['', [Validators.maxLength(20)]],
      purposeId: [''],
      referenceId: ['', [Validators.required]],
    });
    this.singleTransactionForm.get('beneficiaryAccountName')?.disable();
    effect(() => {
      const config = this.configFee();
      if (config) {
        this.maxAmount = config.maxAmount;
        this.singleTransactionForm
          .get('amount')
          ?.setValidators([
            Validators.required,
            Validators.max(this.maxAmount),
            Validators.min(config.minAmount),
          ]);
        this.singleTransactionForm.get('amount')?.updateValueAndValidity();
      }
    });
  }

  get accountNumber(): string {
    return this.singleTransactionForm.get('beneficiaryAccountNo')?.value || '';
  }

  get bankCode(): string {
    return this.singleTransactionForm.get('beneficiaryBankCode')?.value || '';
  }

  isCheckButtonDisabled() {
    // If account number is empty, disable the button
    if (!this.accountNumber) {
      return true;
    }

    if (this.isInProgress) {
      return true;
    }

    // Only disable if same values are being checked again (not for 202 response)
    return (
      this.accountNumber === this.lastAccountNumber &&
      this.bankCode === this.lastBankCode &&
      !this.isInProgress
    );
  }

  ngOnInit(): void {
    this.getListBank();
    this.checkDisbursementCutOff();
  }

  getListBank() {
    this.singleTransactionService
      .getBankList()
      .pipe(
        catchError(({ error }: HttpErrorResponse) => throwError(error)),
        takeUntil(this.destroy$),
      )
      .subscribe((res) => {
        if (res) {
          this.bankList = res.data;
        }
      });
  }

  private showNotification(
    variant: MESSAGE_VARIANT,
    title: string,
    body: string,
  ) {
    this.toastService.postMessage({
      type: MESSAGE_TYPE.MESSAGE,
      variant,
      title,
      body,
    });
  }

  private resetStates() {
    this.isLoading = false;
    this.isInProgress = false;
  }

  private clearAccountErrors() {
    this.singleTransactionForm.get('beneficiaryAccountNo')?.setErrors(null);
  }

  checkAccountNumber() {
    this.isLoading = true;
    this.isInProgress = false; // Reset in-progress state when starting new check
    this.isStillPending = false;
    this.lastAccountNumber = this.accountNumber;
    this.lastBankCode = this.bankCode;

    const timeoutWarning = setTimeout(() => {
      this.isLoading = false;
      this.isInProgress = true;
      this.isStillPending = true;
      this.lastAccountNumber = ''; // Reset last values to allow recheck
      this.lastBankCode = '';
      this.singleTransactionForm.patchValue({
        beneficiaryAccountName: null,
      });
      this.clearAccountErrors();
      this.showNotification(
        MESSAGE_VARIANT.WARN,
        'Account Validation Pending!',
        'Please <b>click check account again</b> to validate or you can continue to create the transaction.',
      );
    }, 10000);

    this.singleTransactionService
      .beneficiaryCheck({
        additionalInfo: {},
        beneficiaryAccountNo: this.accountNumber,
        beneficiaryBankCode: this.bankCode,
      })
      .pipe(
        catchError(({ error, status }: HttpErrorResponse) => {
          this.resetStates();
          this.isStillPending = false;
          clearTimeout(timeoutWarning);
          this.showNotification(
            MESSAGE_VARIANT.DANGER,
            'Account validation failed',
            'The account could not be found.',
          );
          this.singleTransactionForm.get('beneficiaryAccountNo')?.setErrors(
            { invalid: true },
            {
              emitEvent: true,
            },
          );
          this.singleTransactionForm.patchValue({
            beneficiaryAccountName: null,
          });
          return of(error);
        }),
        takeUntil(this.destroy$),
        finalize(() => {
          clearTimeout(timeoutWarning);
        }),
      )
      .subscribe((res: BeneficiaryResponse) => {
        if (res?.data) {
          this.resetStates();
          if (res.code === '00' && res.message === 'request in progress') {
            this.lastAccountNumber = ''; // Reset last values to allow recheck
            this.lastBankCode = '';
            this.isStillPending = true;
            return;
          }
          this.isStillPending = false;
          this.showNotification(
            MESSAGE_VARIANT.SUCCESS,
            'Account Validation Successful!',
            'The account has been successfully validated.',
          );
          this.singleTransactionForm.patchValue(
            {
              beneficiaryAccountName: res.data.beneficiaryAccountName,
              beneficiaryBankCode: res.data.beneficiaryBankCode,
              beneficiaryAccountNo: res.data.beneficiaryAccountNo,
              beneficiaryBankName: this.bankList.find(
                (bank) => bank.code === res.data.beneficiaryBankCode,
              )?.name,
              purposeId: res.data.uuid,
            },
            {
              emitEvent: true,
            },
          );

          this.maxAmount = res.data?.metadata?.isOverbooking ? res.data?.metadata?.maxAmount : this.configFee().maxAmount;
          this.singleTransactionForm.get('amount')?.setValidators([
            Validators.required,
            Validators.max(this.maxAmount),
            Validators.min(this.configFee().minAmount),
          ]);
          this.singleTransactionForm.get('amount')?.updateValueAndValidity();
        }
      });
  }

  checkDisbursementCutOff() {
    this.singleTransactionService
      .checkCutOffTime()
      .subscribe((response: DisbursementCutOffStatusResponse) => {
        if (!response || !response?.data) {
          return;
        }

        const { status, banner, time } = response.data;

        if (status != 'ONGOING') {
          return;
        }

        this.bannerStateService.setState({
          message: banner,
          icon: 'caution-invert',
          height: '28px',
          width: '28px',
        });
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.bannerStateService.clearState();
  }

  onSubmit() {
    const transactionData = this.singleTransactionForm.getRawValue();

    const transactionConfirmationDialogConfig = {
      width: '550px',
      height: 'fit-content',
      title: 'Transaction Confirmation',
      message:
        'Make sure all information is correct before you proceed to make a transaction.',
      body: {
        ...transactionData,
        merchantName: this.userData?.merchantName,
        fee: this.fee(),
      },
    };

    this.dialogComponentService
      .openTransactionConfirmationDialog(transactionConfirmationDialogConfig)
      .onClose.pipe(
        filter((res) => !!res),
        takeUntil(this.destroy$),
        switchMap(() => this.createTransaction(transactionData)),
      )
      .subscribe();
  }

  createTransaction(payload: SingleTransactionData): Observable<any> {
    return this.singleTransactionService.postSingleTransaction(payload).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        return throwError(() =>
          this.dialogComponentService.openErrorDialog({
            width: '700px',
            height: '500px',
            body: {
              title: 'Transaction Creation Failed',
              message: errorResponse.error?.errors,
            },
          }),
        );
      }),
      takeUntil(this.destroy$),
      tap((res: SingleTransactionResponse) => {
        if (res) {
          this.openSuccessDialog();
        }
      }),
      switchMap(() => of(null)),
    );
  }

  openSuccessDialog() {
    this.toastService.postMessage({
      type: MESSAGE_TYPE.MESSAGE,
      variant: MESSAGE_VARIANT.SUCCESS,
      title: 'Single Transaction Created!',
      body: 'Your transaction has been successfully created.',
    });
    this.router.navigateByUrl('/disbursement/disbursement-history');
  }
}
