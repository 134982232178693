export const SENSITIVE_PAYLOAD = ['password', 'token'];

export const maskPayload = (payload: any) => {
  for (const key in payload) {
    if (SENSITIVE_PAYLOAD.includes(key)) {
      payload[key] = '***';
    }
  }
  return payload;
};
