import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgLoaderModule } from '@merchant-portal/app/components/icon/svg-loader/svg-loader/svg-loader.module';
import { BlockLoadingComponent } from '@merchant-portal/app/components/loading/block-loading/block-loading.component';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { BreadcrumbComponent } from 'projects/merchant-portal/src/app/components/breadcrumb/breadcrumb.component';
import { BatchPreviewComponent } from './batch-preview.component';
import { BatchPreviewApiFatoryService } from './factory/batch-preview-api-factory.service';
import { BatchPreviewFactoryService } from './factory/batch-preview-store-factory';
import { BatchPreviewApiService } from './services/batch-preview.api.service';
import { BatchPreviewService } from './services/batch-preview.service';

@NgModule({
  declarations: [BatchPreviewComponent],
  imports: [
    CommonModule,
    BreadcrumbComponent,
    PaginatorModule,
    ButtonModule,
    BlockLoadingComponent,
    SvgLoaderModule,
  ],
  providers: [
    {
      provide: BatchPreviewFactoryService,
      useClass: BatchPreviewService,
    },
    {
      provide: BatchPreviewApiFatoryService,
      useClass: BatchPreviewApiService,
    },
  ],
})
export class BatchPreviewModule {}
