import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {

    // header registration
    request = this.addTimeZoneOffset(request);

    return next.handle(request);
  }

  private addTimeZoneOffset(
  request: HttpRequest<unknown>,
  ): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });
  }
}
