import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@merchant-portal/app/core/auth.service';
import { DashboardAction } from '@merchant-portal/app/features/dashboard/store/dashboard.action';
import { PushNotificationPayload } from '@merchant-portal/app/models/notification/notification.interface';
import { StompService } from '@merchant-portal/app/services/stomp/stomp.service';
import { environment } from '@merchant-portal/environments/environment';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  imports: [ToastModule, CommonModule],
  standalone: true,
})
export class NotificationComponent implements OnInit {
  userId = '';
  appName = environment.appName;
  isConnected = false;

  constructor(
    private stompService: StompService,
    private messageService: MessageService,
    private authService: AuthService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    if (!this.appName) {
      console.error(
        'App name and version are required to subscribe to notifications',
      );
      return;
    }

    // Watch notifications for appName and appVersion (new feature)
    // this.stompService
    //   .watchUserNotifications(this.appName)
    //   .subscribe((message) => {
    //     try {
    //       const notification = JSON.parse(
    //         message.body,
    //       ) as PushNotificationPayload;
    //       const currentAppVersion = environment.appVersion;
    //       const notificationAppVersion = notification?.type;
    //       if (currentAppVersion !== notificationAppVersion) {
    //         this.messageService.add({
    //           key: 'notification',
    //           data: {
    //             title: 'New Version Available!',
    //             body: `Your application version (${currentAppVersion}) is outdated. Please refresh to update the version.`,
    //           },
    //         });
    //       }
    //     } catch (error) {
    //       console.error('Failed to process app notification message:', error);
    //     }
    //   });

    this.userId = this.authService.getUserInfo()?.uuid ?? '';
    if (this.userId) {
      this.watchUserNotifications(this.userId);
    } else {
      this.authService.userLoggedIn$.subscribe((userId) => {
        if (userId) {
          this.watchUserNotifications(userId);
        } else {
          console.warn('No userId found, skipping notification subscription.');
        }
      });
    }

    this.stompService.connectionState$.subscribe((state) => {
      this.isConnected = state === 3; // 3 is the OPEN state
    });
  }

  watchUserNotifications(userId: string) {
    this.stompService.watchUserNotifications(userId).subscribe({
      next: (message) => {
        try {
          const notification = JSON.parse(
            message.body,
          ) as PushNotificationPayload; // Ensure the message is JSON
          this.messageService.add({
            key: 'notification',
            data: {
              title: notification.subject,
              body: notification.message,
            },
            life: 3000, // Display duration in milliseconds
          });
          this.store.dispatch(
            DashboardAction.setNotification({ status: false }),
          );
        } catch (error) {
          console.error('Failed to process user notification message:', error);
        }
      },
      error: (err) => console.error('Error subscribing to notifications:', err),
    });
  }
}
