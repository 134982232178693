import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, Observable, throwError } from 'rxjs';
import { DashboardAction } from '../features/dashboard/store/dashboard.action';

@Injectable()
export class MerchantAccessInterceptor implements HttpInterceptor {
  private store = inject(Store);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {      
        if (error.status === 403 && error.error?.message.startsWith('merchant is not allowed to use')) {
          this.store.dispatch(DashboardAction.requestRouteFailed({ url: request.url }));
          // need to override the error message to break the process
          return throwError(() => new Error('Merchant access denied'));
        }
        return throwError(error);
      })
    );
  }
}
