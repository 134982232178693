import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@merchant-portal/environments/environment';

export interface MerchantProductResponse {
  code: string;
  message: string;
  data: MerchantProduct[];
}

export interface MerchantProduct {
    productId: string;
    productName: string;
    active: boolean;
    createdAt: string; // ISO 8601 date string
    updatedAt: string; // ISO 8601 date string
}

@Injectable()
export class MerchantProductService {
  url = environment.backend_portal_url;
  http = inject(HttpClient);

  getActiveProducts() {
    return this.http.get<MerchantProductResponse>(this.url + 'api/v1/merchants/actived-products');
  }
}
