import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { DashboardAction } from '@merchant-portal/app/features/dashboard/store/dashboard.action';
import { dashboardFeature } from '@merchant-portal/app/features/dashboard/store/dashboard.reducer';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs';
import { AuthService } from '../auth.service';

export const hasPermissionGuardGuard: CanActivateFn = (route, state) => {
  const store = inject(Store);
  store.dispatch(DashboardAction.getMenuAndPermissions());
  const optionalPermissions: string[] = route.data['optionalPermissions'] ?? [];
  return store.select(dashboardFeature.selectPermissionList).pipe(
    filter((data) => !!data),
    map((data) => {
      if (
        data?.includes(route.data['userAccess']) ||
        data?.some((item) => optionalPermissions.includes(item))
      ) {
        return true;
      } else {
        store.dispatch(DashboardAction.requestRouteFailed({ url: state.url }));
        return true;
      }
    }),
  );
};

export const excludeRoleGuard: CanActivateFn = (route, state) => {
  const store = inject(AuthService);
  const router = inject(Router);
  const optionalPermissions: string[] = route.data['excludeRoles'] ?? [];
  const rediretUrl = route.data['redirectUrl'] ?? '/';
  const canAccess = optionalPermissions.includes(
    store.getUserInfo()?.role ?? '',
  );
  if (!store.getUserInfo()?.role) {
    return true;
  }
  // return true;
  if (!canAccess) {
    return true;
  } else {
    return router.navigateByUrl(rediretUrl);
  }
};

export const merchantProductPermissionGuard: CanActivateFn = (route, state) => {
  const store = inject(Store);
  store.dispatch(DashboardAction.getMerchantProductPermission());
  return store.select(dashboardFeature.selectMerchantProductPermission).pipe(
    filter((data) => !!data),
    map((data) => {
      if (data?.includes(route.data['merchantProductPermission'])) {
        return true;
      }

      store.dispatch(DashboardAction.requestRouteFailed({ url: state.url }));
      return true;
    }),
  );
}

export const hasPermissionTabviewGuard: CanActivateFn = (route, state) => {
  const store = inject(Store);
  const router = inject(Router);

  store.dispatch(DashboardAction.getMenuAndPermissions());

  return store.select(dashboardFeature.selectPermissionList).pipe(
    filter((data) => !!data),
    map((data) => {
      if (data?.includes(route.data['userAccess'])) {
        return true;
      } else if (
        tabViewUrls.some((item) => item.forbiddenUrl.includes(state.url))
      ) {
        router.navigateByUrl('/payment/withdrawal-history');
        return true;
      }
      return true;
    }),
  );
};

// List of tab view URL mappings
const tabViewUrls = [
  {
    forbiddenUrl: '/payment/payment-history',
    allowedUrl: '/payment/withdrawal-history',
  },
];
