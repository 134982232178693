import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/id';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  IWithdrawalPrepareResponse,
  WithdrawalDestinationSelectOption,
} from '@merchant-portal/app/models/payment/payment.interface';
import { NgxCurrencyDirective } from 'ngx-currency';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { SvgLoaderModule } from '../../../../../components/icon/svg-loader/svg-loader/svg-loader.module';
import { DefaultValuePipe } from '../../../../../pipe/default-value.pipe';
import {
  withdrawalDestinations,
  withdrawalDestinationType,
} from '../constant/withdrawal-destination.constant';
registerLocaleData(localeFr, 'id');

@Component({
  selector: 'app-withdrawal-request-component-dialog',
  templateUrl: './withdrawal-request.component-dialog.html',
  styleUrls: ['./withdrawal-request.component-dialog.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    InputTextModule,
    ButtonModule,
    DialogModule,
    ToastModule,
    FormsModule,
    SvgLoaderModule,
    NgxCurrencyDirective,
    DefaultValuePipe,
    DropdownModule,
  ],
})
export class WithdrawalRequestDialogComponent {
  amount: number | null = null;
  minAmount: number = 10000;
  showTooltip = false;
  content: IWithdrawalPrepareResponse['data'];
  destinations = withdrawalDestinations;
  destination: WithdrawalDestinationSelectOption | null =
    this.destinations.find(
      (item) => item.code === withdrawalDestinationType.BANK_TRANSFER,
    ) ?? null;

  constructor(
    private ref: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
  ) {
    this.content = this.dialogConfig.data;
  }

  confirm() {
    this.ref.close({
      amount: this.amount,
      destination: this.destination,
    });
  }

  cancel() {
    this.ref.close();
  }

  isAllowWithdrawal(): boolean {
    const isAmountMoreThanMinimum =
      this.amount !== null && this.amount >= this.minAmount;
    const isDestinationSelected = this.destination !== null;
    const isBankAvailable =
      (this.destination?.code === withdrawalDestinationType.BANK_TRANSFER &&
        this.content.bankAccounts &&
        this.content.bankAccounts.length > 0) ||
      this.destination?.code !== withdrawalDestinationType.BANK_TRANSFER;

    return isAmountMoreThanMinimum && isDestinationSelected && isBankAvailable;
  }
}
