import { HttpErrorResponse } from '@angular/common/http';
import { IMenuResponse } from '@merchant-portal/app/models/menu/menu.interface';
import { BalanceAmount } from '@merchant-portal/app/models/transaction-history/transaction-history.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { MenuAndPermissionsData } from '../services/menu-and-permission.service';
import { MerchantProduct } from '../services/merchant-product.service';

export const DashboardAction = createActionGroup({
  source: 'Dashboard',
  events: {
    'Get Disbursement Fee': emptyProps(),
    'Get Disbursement Success': props<{
      fee: number;
      maxAmount: number;
      minAmount: number;
    }>(),
    'Get Disbursement Failed': props<{ error: HttpErrorResponse }>(),
    'Set Previous Url': props<{ url: string }>(),
    'Set Notification': props<{ status: boolean }>(),
    'Request User Menus': props<{ id: string }>(),
    'Request User Menus Success': props<{ menus: IMenuResponse }>(),
    'Request User Menus Failed': props<{ error: string }>(),
    'Get Menu And Permissions': emptyProps(),
    'Get Menu And Permissions Success': props<{
      menus: MenuAndPermissionsData[];
    }>(),
    'Get Menu And Permissions Failed': props<{ error: HttpErrorResponse }>(),
    'Request Route': emptyProps(),
    'Request Route Success': props<{ url: string[] }>(),
    'Request Route Failed': props<{ url: string }>(),
    'Get Merchant Product Permission': emptyProps(),
    'Get Merchant Product Permission Success': props<{
      products: MerchantProduct[];
    }>(),
    'Get Merchant Product Permission Failed': props<{
      error: HttpErrorResponse;
    }>(),
    'Request Withdrawal': props<{
      balance: BalanceAmount;
      isWallet?: boolean;
    }>(),
    'Set Withdrawal Loading': props<{ loading: boolean }>(),
    'Withdrawal Prepare Failed': props<{ error: string }>(),
    'Confirm Withdrawal': props<{ request: any }>(),
    'Withdrawal Failed': props<{
      error: string;
    }>(),
    'Withdrawal Success': props<{
      destination: 'BANK_TRANSFER' | 'BALANCE_TRANSFER';
    }>(),
  },
});
