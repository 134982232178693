import { WithdrawalDestinationSelectOption } from '@merchant-portal/app/models/payment/payment.interface';

export const withdrawalDestinationType = {
  BANK_TRANSFER: 'BANK_TRANSFER',
  BALANCE_TRANSFER: 'BALANCE_TRANSFER',
};

export const withdrawalDestinations: WithdrawalDestinationSelectOption[] = [
  {
    name: 'Bank Account',
    code: withdrawalDestinationType.BANK_TRANSFER,
  },
  {
    name: 'Payout Balance',
    code: withdrawalDestinationType.BALANCE_TRANSFER,
  },
];
