import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'transactionTypeReasonPipe',
  standalone: true,
})
export class TransactionReasonTypePipe implements PipeTransform {
  transform(value: string | null ): string {
    let res = "";
    switch (value) {
        case 'CUT_OFF_TIME':
            res = 'Cut Off Time';
            break;
        case 'DECLINED_BENEFICIARY_RESTRICTION':
            res = 'Payout request declined due to beneficiary limit restrictions.';
            break;
        default:
            res = value ?? "";
            break
        }

    return res;
  }
}
