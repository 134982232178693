import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { maskPayload } from '../core/helper/sensitive-payload.constant';
import { REPORT_ERROR_TOKEN } from '../core/services/global-token';
import { NewRelicAgentService } from '../core/services/new-relic-agent.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private newRelicAgent: NewRelicAgentService = inject(NewRelicAgentService);
  private messageService = inject(MessageService);

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const requestClone = req.clone({
      setHeaders: {
        'Accept-Language': 'en',
      },
    });

    return next.handle(requestClone).pipe(
      catchError((error: HttpErrorResponse) => {
        // console.log(error);

        if (error.status === 504) {
          this.messageService.add({
            key: 'message',
            severity: 'error',
            data: {
              title: 'Your Request Is Timeout!',
              body: 'The server is not responding. Please try again later.',
            },
          });
        }

        let attribute = {};
        if (requestClone.context.get(REPORT_ERROR_TOKEN)) {
          attribute = {
            token: req.context.get(REPORT_ERROR_TOKEN),
          };
        }
        attribute = {
          ...attribute,
          url: req.url,
          request: maskPayload(req.body),
          error: error,
          params: maskPayload(requestClone.params),
        };
        this.newRelicAgent.handleError(error, attribute);
        return throwError(error);
      }),
    );
  }
}
