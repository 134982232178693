import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  excludeRoleGuard,
  hasPermissionGuardGuard,
} from '@merchant-portal/app/core/user-access/has-permission-guard.guard';
import { BeneficiaryListComponent } from './beneficiary-list/beneficiary-list.component';
import { DisbursementComponent } from './disbursement.component';
import { DisbursementService } from './services/disbursement.service';

const routes: Routes = [
  {
    path: '',
    canActivate: [excludeRoleGuard],

    component: DisbursementComponent,
    data: {
      redirectUrl: '/xb/payout',
      excludeRoles: ['Crossborder Operator'],
    },
  },
  {
    path: '',
    canActivate: [excludeRoleGuard],

    component: DisbursementComponent,
    data: {
      redirectUrl: '/platform',
      excludeRoles: ['Platform'],
    },
  },
  {
    canActivate: [hasPermissionGuardGuard],
    path: 'disbursement-history',
    data: {
      userAccess: 'disbursement.disbursement-history.view',
    },
    loadChildren: () =>
      import('./disbursement-history/disbursement-history-layout.module').then(
        (m) => m.DisbursementHistoryLayoutModule,
      ),
  },
  {
    path: 'create-transaction',
    canActivateChild: [hasPermissionGuardGuard],

    loadChildren: () =>
      import('./create-transaction/create-transaction.routing.module').then(
        (m) => m.CreateTransactionRoutingModule,
      ),
    data: {
      breadcrumb: 'Create Transaction',
      userAccess: 'disbursement.disbursement-create.view',
    },
  },
  {
    path: 'beneficiary-list',
    canActivate: [hasPermissionGuardGuard],
    data: {
      breadcrumb: 'Beneficiary List',
      userAccess: 'disbursement.disbursement-beneficiary-list.view',
    },
    component: BeneficiaryListComponent,
  },
  {
    path: 'approval',
    data: {
      breadcrumb: 'Approval',
      userAccess: 'disbursement.disbursement-approval.view',
    },
    canActivate: [hasPermissionGuardGuard],

    loadChildren: () =>
      import('./approval/approval.routing.module').then(
        (m) => m.ApprovalRoutingModule,
      ),
  },
  {
    path: 'top-up-simulation',
    data: {
      breadcrumb: 'Top Up Balance',
    },
    resolve: {
      setWalletMode: () => {
        const disbursementService = inject(DisbursementService);
        disbursementService.setWalletTopUp(false);
        return false;
      },
    },
    loadComponent: () =>
      import('./top-up-simulation/top-up-simulation.component').then(
        (m) => m.TopUpSimulationComponent,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DisbursementRoutingModule {}
